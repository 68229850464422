export default {
    setTransactionFilterFieldsMutation(state, payload)
    {
        state.transactionFilter.fields.transactionCreatedAt = payload.transaction_created_at;
        state.transactionFilter.fields.transactionAmount = payload.transaction_amount;
        state.transactionFilter.fields.transactionType = payload.transaction_type;
        state.transactionFilter.fields.transactionArticle = payload.transaction_article;
        state.transactionFilter.fields.transactionUserName = payload.transaction_user_name;
        state.transactionFilter.fields.transactionUser = payload.transaction_user;
        state.transactionFilter.fields.filterStartDate = payload.filter_start_date;
        state.transactionFilter.fields.filterEndDate = payload.filter_end_date;
        state.transactionFilter.main_filter = payload.main_filter;
    },
    setTransactionList(state, payload)
    {
        state.transactionList = payload;
    },
    setTransactionListCount(state, payload) {
        state.transactionListCount = payload;
    },
    setSupplementAmount(state, payload) {
        state.supplement_amount = payload;
    },
    setClientBankReceiptFile(state, payload) {
        state.client_bank_receipt_file = payload;
    },
    setBankReceiptFile(state, payload) {
        state.bank_receipt_file = payload;
    },
}

export default {
    getTicketList: (state) => {
        return state.ticketList;
    },
    getTicketListCount: (state) => {
        return state.ticketListCount;
    },
    getTicketMainFilter: (state) => {
        return state.ticketFilter.main_filter;
    },
    getTicketFilterFields: (state) => {
        return state.ticketFilter.fields;
    },
    getTicketModalOpen: (state) => {
        return state.ticketModalOpen;
    },
    getTicketIdAndTicketIndex: (state) => {
        return {
            ticket_id: state.ticket_id,
            ticket_index: state.ticket_index
        };
    },
}

export default {
    setTariffFilterFieldsMutation(state, payload)
    {
        state.tariffFilter.fields.tariffNumber = payload.tariff_number;
        state.tariffFilter.fields.tariffTitle = payload.tariff_title;
        state.tariffFilter.fields.tariffCost = payload.tariff_cost;
        state.tariffFilter.fields.tariffAmountDocuments = payload.tariff_amount_documents;
        state.tariffFilter.fields.tariffStatus = payload.tariff_status;
        state.tariffFilter.main_filter = payload.main_filter;
    },
    setTariffList(state, payload)
    {
        state.tariffList = payload;
    },
    setTariffListCount(state, payload) {
        state.tariffListCount = payload;
    },
    deleteTariffsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`tariff-id-${item}`).checked = false;

                index = state.tariffList.findIndex(tariff => tariff.id == item);

                if (index !== -1) {
                    state.tariffList.splice(index, 1);
                }
            });
        }
    },
    setTariffModalOpenMutation(state, payload)
    {
        state.tariffModalOpen = payload;
    },
    setTariffIdAndTariffIndexToModalFormMutation(state, payload)
    {
        state.tariff_id = payload.tariff_id;
        state.tariff_index = payload.tariff_index;
    },
    addTariffToTariffList(state, payload)
    {
        state.tariffList.push(payload);
    },
    updateTariff(state, payload)
    {
        state.tariffList[payload.tariff_index].title = payload.title;
        state.tariffList[payload.tariff_index].cost = payload.cost;
    },
}

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    documentList: [],
    documentListCount: null,
    documentFilter: {
        main_filter: null,
        fields: {
            documentNumber: null,
            documentTitle: null,
            documentUserName: null,
            documentCompanyTitle: null,
            documentCompanyRole: null,
            documentHash: null,
            documentCreatedAt: null,
            documentStatus: null,
            documentExecutorStampDate: null,
            documentCustomerStampDate: null,
        },
    },
    documentModalOpen: false,
    document_id: null,
    document_index: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

import router from "../../../router";
import axios from "axios";

export default {
    loadUserList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['accesses/getUserFilterFields'];
            let main_filter = this.getters['accesses/getUserMainFilter'];

            dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

            commit('setUserList', []);

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setUserFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.userNumber && router.currentRoute.query.user_number) {
                filter_fields.userNumber = router.currentRoute.query.user_number;

                commit('setUserFilterFieldsMutation', {field_name: 'userNumber', field_value: router.currentRoute.query.user_number})
            }

            if (!filter_fields.userName && router.currentRoute.query.user_name) {
                filter_fields.userName = router.currentRoute.query.user_name;

                commit('setUserFilterFieldsMutation', {field_name: 'userName', field_value: router.currentRoute.query.user_name})
            }

            if (!filter_fields.userEmail && router.currentRoute.query.user_email) {
                filter_fields.userEmail = router.currentRoute.query.user_email;

                commit('setUserFilterFieldsMutation', {field_name: 'userEmail', field_value: router.currentRoute.query.user_email})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/client/list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setUserList', response.data.data.result.clients);
                commit('setUserListCount', response.data.data.result.clients_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setUserFilterFields({ commit }, payload)
    {
        commit('setUserFilterFieldsMutation', payload);
    },
    deleteUsers({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/client/delete-clients', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deleteUsersMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setUserModalOpen({commit}, payload)
    {
        commit('setUserModalOpenMutation', payload);
    },
    setUserIdAndUserIndexToModalForm({commit}, payload)
    {
        commit('setUserIdAndUserIndexToModalFormMutation', payload);
    },
    addUserToUserList({commit}, payload)
    {
        commit('addUserToUserList', payload);
    },
    updateUser({ commit }, payload)
    {
        commit('updateUser', payload);
    },
}

import router from "../../../router";
import axios from "axios";

export default {
    loadTariffList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['tariffs/getTariffFilterFields'];
            let main_filter = this.getters['tariffs/getTariffMainFilter'];

            dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

            commit('setTariffList', []);

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setTariffFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.tariffNumber && router.currentRoute.query.tariff_number) {
                filter_fields.tariffNumber = router.currentRoute.query.tariff_number;

                commit('setTariffFilterFieldsMutation', {field_name: 'tariffNumber', field_value: router.currentRoute.query.tariff_number})
            }

            if (!filter_fields.tariffTitle && router.currentRoute.query.tariff_title) {
                filter_fields.tariffTitle = router.currentRoute.query.tariff_title;

                commit('setTariffFilterFieldsMutation', {field_name: 'tariffTitle', field_value: router.currentRoute.query.tariff_title})
            }

            if (!filter_fields.tariffCost && router.currentRoute.query.tariff_cost) {
                filter_fields.tariffCost = router.currentRoute.query.tariff_cost;

                commit('setTariffFilterFieldsMutation', {field_name: 'tariffCost', field_value: router.currentRoute.query.tariff_cost})
            }

            if (!filter_fields.tariffAmountDocuments && router.currentRoute.query.tariff_amount_documents) {
                filter_fields.tariffAmountDocuments = router.currentRoute.query.tariff_amount_documents;

                commit('setTariffFilterFieldsMutation', {field_name: 'tariffAmountDocuments', field_value: router.currentRoute.query.tariff_amount_documents})
            }

            if (!filter_fields.tariffStatus && router.currentRoute.query.tariff_status) {
                filter_fields.tariffStatus = router.currentRoute.query.tariff_status;

                commit('setTariffFilterFieldsMutation', {field_name: 'tariffStatus', field_value: router.currentRoute.query.tariff_status})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/tariff/list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setTariffList', response.data.data.result.tariffs);
                commit('setTariffListCount', response.data.data.result.tariffs_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setTariffFilterFields({ commit }, payload)
    {
        commit('setTariffFilterFieldsMutation', payload);
    },
    deleteTariffs({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/tariff/delete-tariffs', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deleteTariffsMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setTariffModalOpen({commit}, payload)
    {
        commit('setTariffModalOpenMutation', payload);
    },
    setTariffIdAndTariffIndexToModalForm({commit}, payload)
    {
        commit('setTariffIdAndTariffIndexToModalFormMutation', payload);
    },
    addTariffToTariffList({commit}, payload)
    {
        commit('addTariffToTariffList', payload);
    },
    updateTariff({ commit }, payload)
    {
        commit('updateTariff', payload);
    },
}

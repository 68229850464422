import {mapGetters} from "vuex";
import axios from "axios";

export default {
    name: "LeftNav",
    data() {
        return {
            noReaded: 0,
            links: [
                {
                    title: "Клиенты",
                    href: "/clients",
                    icon: "person-plus",
                    name: "clients",
                },
                {
                    title: "Компании",
                    href: "/companies",
                    icon: "shop",
                    name: "companies",
                },
                {
                    title: "Документы",
                    href: "/documents",
                    icon: "files",
                    name: "documents",
                },
                {
                    title: "Тарифы",
                    href: "/tariffs",
                    icon: "wallet",
                    name: "tariffs",
                },
                {
                    title: "Настройки",
                    href: "/settings",
                    icon: "wrench",
                    name: "settings",
                },
                {
                    title: "Транзакции",
                    href: "/transactions",
                    icon: "receipt",
                    name: "transactions",
                },
                {
                    title: "Поддержка",
                    href: "/tickets",
                    icon: "chat-square-text-fill",
                    name: "tickets",
                },
                {
                    title: "Страницы",
                    href: "/pages",
                    icon: "file-code",
                    name: "pages",
                },
            ],
            isActive: false,
            status: false,
            deal_board_view: null,
            currentUser: {
                avatar: null,
                profile_type: 'web-dev',
            },

            intervalGetCountNotVerifiedClients: null,
            count_not_verified_clients: null,
        }
    },
    created() {
        document.getElementsByTagName('body')[0].addEventListener('click', e => {
            if (!e.target.closest('.profile-popover') || e.target.classList.contains('profile__popover-item-link-close') === true) {
                this.$root.$emit('bv::hide::popover')
            }
        });

        document.querySelectorAll('.profile__popover-item-link').forEach(el =>
            el.addEventListener("click", (e) => this.$root.$emit('bv::hide::popover'))
        );
    },
    mounted() {
        const app = this;

        app.isActive = localStorage.getItem('token') ?? false;
    },
    computed: {
        ...mapGetters({
            getCurrentMenuItem: 'navigation/getCurrentMenuItem',
            getCountNotVerifiedClients: 'settings/getCountNotVerifiedClients',
            getCountNotClosedTickets: 'settings/getCountNotClosedTickets',
            //getDealBoardView: 'systemData/getDealBoardView',
        }),
    },
    methods: {
        logout()
        {
            const app = this;

            app.$store.dispatch('currentUser/logoutUser');
        },
        routePush(link)
        {
            if (link.name != 'statistic') {
                this.$router.push(link.href);
            }
        },
        showMessanger(status)
        {
            this.$store.dispatch('messanger/setMessangerShowed', status);
        },
        checkUserReadAccess(link)
        {
            const app = this;

            let result = false;

            if (app.currentUser) {
                if (app.currentUser.roles) {
                    let permissionIndex = null;

                    app.currentUser.roles.forEach(function(role, index, array) {
                        permissionIndex = role.permission_.findIndex(permission => permission.name == link.name);

                        if (permissionIndex >= 0) {
                            if (role.permission_[permissionIndex].read == true) {
                                result = true;

                                return result;
                            }
                        }
                    });
                }
            }

            return result;
        },
        getCountNotVerifiedClients() {
            const app = this;

            axios.post('/api/client/get-count-not-verified-clients', {
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    if (result.result.count) {
                        app.count_not_verified_clients = result.result.count;
                    }
                } else {
                    if (result.result.message) {
                        app.$toaster.error(result.result.message);
                    } else {
                        app.$toaster.error('Ошибка получения данных!');
                    }
                }
            }).catch(error => {
                console.log(error);
                app.$toaster.error('Ошибка получения');
            });
        },
        setIntervalGetCountNotVerifiedClients() {
            const app = this;

            app.intervalGetCountNotVerifiedClients = setInterval(app.getCountNotVerifiedClients, 30000)
            app.$on('hook:beforeDestroy', () => clearInterval(app.intervalGetCountNotVerifiedClients));
        },
    },
    watch: {
        getNoReaded: {
            immediate: true,
            deep: false,
            handler(newValue, oldValue) {
                this.noReaded = newValue;
            }
        },
    }
}

import LeftNav from '../../components/template/left-nav/LeftNav.vue';
import Avatar from '../../components/template/avatar/Avatar.vue';

export default {
    name: "AppLayout",
    components: {
        LeftNav,
        Avatar,
    }
}

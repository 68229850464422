import router from "../../../router";
import axios from "axios";

export default {
    loadClientList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['clients/getClientFilterFields'];
            let main_filter = this.getters['clients/getClientMainFilter'];

            dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

            commit('setClientList', []);

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setClientFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.userNumber && router.currentRoute.query.user_number) {
                filter_fields.userNumber = router.currentRoute.query.user_number;

                commit('setClientFilterFieldsMutation', {field_name: 'userNumber', field_value: router.currentRoute.query.user_number})
            }

            if (!filter_fields.userName && router.currentRoute.query.user_name) {
                filter_fields.userName = router.currentRoute.query.user_name;

                commit('setClientFilterFieldsMutation', {field_name: 'userName', field_value: router.currentRoute.query.user_name})
            }

            if (!filter_fields.userEmail && router.currentRoute.query.user_email) {
                filter_fields.userEmail = router.currentRoute.query.user_email;

                commit('setClientFilterFieldsMutation', {field_name: 'userEmail', field_value: router.currentRoute.query.user_email})
            }

            if (!filter_fields.userType && router.currentRoute.query.user_type) {
                filter_fields.userType = router.currentRoute.query.user_type;

                commit('setClientFilterFieldsMutation', {field_name: 'userType', field_value: router.currentRoute.query.user_type})
            }

            if (!filter_fields.userDeleted && router.currentRoute.query.user_deleted) {
                filter_fields.userDeleted = router.currentRoute.query.user_deleted;

                commit('setClientFilterFieldsMutation', {field_name: 'userDeleted', field_value: router.currentRoute.query.user_deleted})
            }

            if (!filter_fields.userActivatedAt && router.currentRoute.query.user_activated_at) {
                filter_fields.userActivatedAt = router.currentRoute.query.user_activated_at;

                commit('setClientFilterFieldsMutation', {field_name: 'userActivatedAt', field_value: router.currentRoute.query.user_activated_at})
            }

            if (!filter_fields.userCreatedAt && router.currentRoute.query.user_created_at) {
                filter_fields.userCreatedAt = router.currentRoute.query.user_created_at;

                commit('setClientFilterFieldsMutation', {field_name: 'userCreatedAt', field_value: router.currentRoute.query.user_created_at})
            }

            if (!filter_fields.userPhone && router.currentRoute.query.user_phone) {
                filter_fields.userPhone = router.currentRoute.query.user_phone;

                commit('setClientFilterFieldsMutation', {field_name: 'userPhone', field_value: router.currentRoute.query.user_phone})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;
            payload['orderById'] = 'desc';

            axios.post('/api/client/list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setClientList', response.data.data.result.clients);
                commit('setClientListCount', response.data.data.result.clients_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setClientFilterFields({ commit }, payload)
    {
        commit('setClientFilterFieldsMutation', payload);
    },
    deleteClients({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/client/delete-clients', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deleteClientsMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setClientModalOpen({commit}, payload)
    {
        commit('setClientModalOpenMutation', payload);
    },
    setClientIdAndClientIndexToModalForm({commit}, payload)
    {
        commit('setClientIdAndClientIndexToModalFormMutation', payload);
    },
    addClientToClientList({commit}, payload)
    {
        commit('addClientToClientList', payload);
    },
    updateClient({ commit }, payload)
    {
        commit('updateClient', payload);
    },
    updateClientBalance({ commit }, payload)
    {
        commit('updateClientBalance', payload);
    },
    getClientInfo({ commit, state }, payload)
    {
        axios.post('/api/client/get-client', {
            id: state.clientList[payload.client_index].id,
        }).then(response => {
            const result = response.data.data;

            if (response.data.data.result.success !== true) {
                alert('Клиент не найден');

                return;
            }

            commit('setCurrentClientInfo', response.data.data.result.client);
        }).catch(error => {
            alert(error);
        });
    },
    setCurrentClientInfo({ commit }, payload)
    {
        commit('setCurrentClientInfo', payload);
    },
    setCurrentClientValue({ commit }, payload)
    {
        commit('setCurrentClientValue', payload);
    },
}

import axios from "axios";
import router from "../../../router";

export default {
    setUser({ commit }, payload)
    {
        commit('setUserMutation', payload);
    },
    initUser({commit, dispatch})
    {
        return new Promise((resolve, reject) => {
            let host = null;

            const partsHost = window.location.host.split('.');

            if (partsHost.length == 3) {
                host = partsHost[0];
            }

            axios.post('/api/user-admin')
                .then((response) => {

                    if (response.data.data.code != 'ok') {
                        localStorage.removeItem('token');
                        window.location.href = '/login';

                        return;
                    }

                    if (!response.data.data.result.user.enabled) {
                        localStorage.removeItem('token');
                        window.location.href = '/login';

                        return;
                    }

                    commit('setUserMutation', response.data.data.result.user);

                    if (response.data.data.result.settings) {
                        if (response.data.data.result.settings.not_verified_client_count) {
                            dispatch('settings/setNotVerifiedClientCount', response.data.data.result.settings.not_verified_client_count, {root: true});
                        }

                        if (response.data.data.result.settings.not_closed_tickets) {
                            dispatch('settings/setNotClosedTickets', response.data.data.result.settings.not_closed_tickets, {root: true});
                        }

                        if (response.data.data.result.settings.roles) {
                            dispatch('settings/setRoles', response.data.data.result.settings.roles, {root: true});
                        }

                        if (response.data.data.result.settings.permissions) {
                            dispatch('settings/setPermissions', response.data.data.result.settings.permissions, {root: true});
                        }
                    }

                    resolve(true);
                })
                .catch(error => {
                        commit('setUserMutation', null);

                        localStorage.removeItem('token');
                        window.location.href = '/login';

                        reject(error);
                    }
                );
        });
    },
    startUserUpdateInterval({ commit }, payload)
    {
        commit('startUserUpdateInterval', payload);
    },
}

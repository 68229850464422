export default {
    setPageFilterFieldsMutation(state, payload)
    {
        state.pageFilter.fields.pageId = payload.page_id;
        state.pageFilter.fields.pageTitle = payload.page_title;
        state.pageFilter.fields.pageSlug = payload.page_slug;
        state.pageFilter.main_filter = payload.main_filter;
    },
    setPageList(state, payload)
    {
        state.pages = payload;
    },
    setPageListCount(state, payload) {
        state.pageListCount = payload;
    },
    deletePagesMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`page-id-${item}`).checked = false;

                index = state.pages.findIndex(page => page.id == item);

                if (index !== -1) {
                    state.pages.splice(index, 1);
                }
            });
        }
    },
    setPageModalOpenMutation(state, payload)
    {
        state.pageModalOpen = payload;
    },
    setPageIdAndPageIndexToModalFormMutation(state, payload)
    {
        state.page_id = payload.page_id;
        state.page_index = payload.page_index;
    },
    addPageToPageList(state, payload)
    {
        state.pages.push(payload);
    },
    updatePage(state, payload)
    {
        state.pages[payload.page_index].title = payload.title;
        state.pages[payload.page_index].slug = payload.slug;
    },
}

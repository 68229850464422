import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    clientList: [],
    clientListCount: null,
    clientFilter: {
        main_filter: null,
        fields: {
            userNumber: null,
            userName: null,
            userEmail: null,
            userType: 'client',
            userDeleted: null,
            userActivatedAt: null,
            userCreatedAt: null,
            userPhone: null,
        },
    },
    clientModalOpen: false,
    client_id: null,
    client_index: null,
    currentClient: {
        id: null,
        name: null,
        phone: null,
        transaction_article: null,
        transaction_comment: null,
        transaction_action: null,
        wallet: {
            id: null,
            balance: null,
        },
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

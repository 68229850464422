export default {
    getUserList: (state) => {
        return state.userList;
    },
    getUserListCount: (state) => {
        return state.userListCount;
    },
    getUserMainFilter: (state) => {
        return state.userFilter.main_filter;
    },
    getUserFilterFields: (state) => {
        return state.userFilter.fields;
    },
    getUserModalOpen: (state) => {
        return state.userModalOpen;
    },
    getUserIdAndUserIndex: (state) => {
        return {
            user_id: state.user_id,
            user_index: state.user_index
        };
    },
}

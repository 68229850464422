import router from "../../../router";
import axios from "axios";

export default {
    loadPermissionList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['permissions/getPermissionFilterFields'];
            let main_filter = this.getters['permissions/getPermissionMainFilter'];

            dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

            commit('setPermissionList', []);

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setPermissionFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.permissionNumber && router.currentRoute.query.permission_number) {
                filter_fields.permissionNumber = router.currentRoute.query.permission_number;

                commit('setPermissionFilterFieldsMutation', {field_name: 'permissionNumber', field_value: router.currentRoute.query.permission_number})
            }

            if (!filter_fields.permissionTitle && router.currentRoute.query.permission_title) {
                filter_fields.permissionTitle = router.currentRoute.query.permission_title;

                commit('setPermissionFilterFieldsMutation', {field_name: 'permissionTitle', field_value: router.currentRoute.query.permission_title})
            }

            if (!filter_fields.permissionSlug && router.currentRoute.query.permission_slug) {
                filter_fields.permissionSlug = router.currentRoute.query.permission_slug;

                commit('setPermissionFilterFieldsMutation', {field_name: 'permissionSlug', field_value: router.currentRoute.query.permission_slug})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/permission/list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setPermissionList', response.data.data.result.permissions);
                commit('setPermissionListCount', response.data.data.result.permissions_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setPermissionFilterFields({ commit }, payload)
    {
        commit('setPermissionFilterFieldsMutation', payload);
    },
    deletePermissions({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/permission/delete-permissions', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deletePermissionsMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setPermissionModalOpen({commit}, payload)
    {
        commit('setPermissionModalOpenMutation', payload);
    },
    setPermissionIdAndPermissionIndexToModalForm({commit}, payload)
    {
        commit('setPermissionIdAndPermissionIndexToModalFormMutation', payload);
    },
    addPermissionToPermissionList({commit}, payload)
    {
        commit('addPermissionToPermissionList', payload);
    },
    updatePermission({ commit }, payload)
    {
        commit('updatePermission', payload);
    },
}

import router from "../../../router";
import axios from "axios";

export default {
    loadPageList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['pages/getPageFilterFields'];
            let main_filter = this.getters['pages/getPageMainFilter'];

            dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

            commit('setPageList', []);

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setPageFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.pageId && router.currentRoute.query.page_id) {
                filter_fields.pageId = router.currentRoute.query.page_id;

                commit('setPageFilterFieldsMutation', {field_name: 'pageId', field_value: router.currentRoute.query.page_id})
            }

            if (!filter_fields.pageTitle && router.currentRoute.query.page_title) {
                filter_fields.pageTitle = router.currentRoute.query.page_title;

                commit('setPageFilterFieldsMutation', {field_name: 'pageTitle', field_value: router.currentRoute.query.page_title})
            }

            if (!filter_fields.pageSlug && router.currentRoute.query.page_slug) {
                filter_fields.pageSlug = router.currentRoute.query.page_slug;

                commit('setPageFilterFieldsMutation', {field_name: 'pageSlug', field_value: router.currentRoute.query.page_slug})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/page/list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setPageList', response.data.data.result.pages);
                commit('setPageListCount', response.data.data.result.pages_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setPageFilterFields({ commit }, payload)
    {
        commit('setPageFilterFieldsMutation', payload);
    },
    deletePages({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/page/delete-pages', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deletePagesMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setPageModalOpen({commit}, payload)
    {
        commit('setPageModalOpenMutation', payload);
    },
    setPageIdAndPageIndexToModalForm({commit}, payload)
    {
        commit('setPageIdAndPageIndexToModalFormMutation', payload);
    },
    addPageToPageList({commit}, payload)
    {
        commit('addPageToPageList', payload);
    },
    updatePage({ commit }, payload)
    {
        commit('updatePage', payload);
    },
}

export default {
    setDocumentFilterFieldsMutation(state, payload)
    {
        state.documentFilter.fields.documentNumber = payload.document_number;
        state.documentFilter.fields.documentTitle = payload.document_title;
        state.documentFilter.fields.documentUserName = payload.document_user_name;
        state.documentFilter.fields.documentCompanyTitle = payload.document_company_title;
        state.documentFilter.fields.documentCompanyRole = payload.document_company_role;
        state.documentFilter.fields.documentHash = payload.document_hash;
        state.documentFilter.fields.documentCreatedAt = payload.document_created_at;
        state.documentFilter.fields.documentStatus = payload.document_status;
        state.documentFilter.fields.documentExecutorStampDate = payload.document_executor_stamp_date;
        state.documentFilter.fields.documentCustomerStampDate = payload.document_customer_stamp_date;
        state.documentFilter.main_filter = payload.main_filter;
    },
    setDocumentList(state, payload)
    {
        state.documentList = payload;
    },
    setDocumentListCount(state, payload) {
        state.documentListCount = payload;
    },
    deleteDocumentsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`document-id-${item}`).checked = false;

                index = state.documentList.findIndex(document => document.id == item);

                if (index !== -1) {
                    state.documentList.splice(index, 1);
                }
            });
        }
    },
    setDocumentModalOpenMutation(state, payload)
    {
        state.documentModalOpen = payload;
    },
    setDocumentIdAndDocumentIndexToModalFormMutation(state, payload)
    {
        state.document_id = payload.document_id;
        state.document_index = payload.document_index;
    },
    updateDocument(state, payload)
    {
        state.documentList[payload.document_index].title = payload.title;
        state.documentList[payload.document_index].executor_title = payload.executor_title;
        state.documentList[payload.document_index].executor_who_signed_document = payload.executor_who_signed_document;
        state.documentList[payload.document_index].executor_job_title = payload.executor_job_title;
    },
    addDocumentToDocumentList(state, payload)
    {
        state.documentList.push(payload);
    },
}

export default {
    setUserMutation(state, payload)
    {
        state.user = payload;
    },
    updateUser(state, payload)
    {
       if (payload) {
           state.user.name = payload.name;
           state.user.surname = payload.surname;
           state.user.patronymic = payload.patronymic;
           state.user.email = payload.email;

           if (payload.avatar) {
               state.user.avatar = payload.avatar;
           }
       }
    },
    startUserUpdateInterval(state, payload)
    {
        state.userUpdateInterval = setInterval(() => {
            this.dispatch('currentUser/initUser');
        }, 30000);
    },
}

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    pages: [],
    pageListCount: null,
    pageFilter: {
        main_filter: null,
        fields: {
            pageId: null,
            pageTitle: null,
            pageSlug: null,
        },
    },
    pageModalOpen: false,
    page_id: null,
    page_index: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "../store";
import axios from "axios";

Vue.use(VueRouter);

function loggedIn()
{
  return localStorage.getItem('token');
}

const router = new VueRouter({
  mode: 'history',

  routes: [
    {
      path: '/login', component: () => import('../components/login/Login.vue'),
      name: 'login',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/', component: () => import('../components/home/Home.vue'),
      name: 'home',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/clients', component: () => import('../components/clients/Clients.vue'),
      name: 'clients',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/documents', component: () => import('../components/documents/Documents.vue'),
      name: 'documents',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/tariffs', component: () => import('../components/tariffs/Tariffs.vue'),
      name: 'tariffs',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/accesses', component: () => import('../components/accesses/Accesses.vue'),
      name: 'accesses',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/transactions', component: () => import('../components/transactions/Transactions.vue'),
      name: 'transactions',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/tickets', component: () => import('../components/tickets/Tickets.vue'),
      name: 'tickets',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/companies', component: () => import('../components/companies/Companies.vue'),
      name: 'companies',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/pages', component: () => import('../components/pages/Pages.vue'),
      name: 'pages',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/roles', component: () => import('../components/role/Role.vue'),
      name: 'roles',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/permissions', component: () => import('../components/permissions/Permissions.vue'),
      name: 'permissions',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn()) {
      next({
        path: '/login',
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (loggedIn()) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  }
});

export default router;

function userHasAccessToApp(to, from, next) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

  store.dispatch('currentUser/initUser');

  next();
}

export default {
    setPermissionFilterFieldsMutation(state, payload)
    {
        state.permissionFilter.fields.permissionNumber = payload.permission_number;
        state.permissionFilter.fields.permissionTitle = payload.permission_title;
        state.permissionFilter.fields.permissionSlug = payload.permission_slug;
        state.permissionFilter.main_filter = payload.main_filter;
    },
    setPermissionList(state, payload)
    {
        state.permissionList = payload;
    },
    setPermissionListCount(state, payload) {
        state.permissionListCount = payload;
    },
    deletePermissionsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`permission-id-${item}`).checked = false;

                index = state.permissionList.findIndex(permission => permission.id == item);

                if (index !== -1) {
                    state.permissionList.splice(index, 1);
                }
            });
        }
    },
    setPermissionModalOpenMutation(state, payload)
    {
        state.permissionModalOpen = payload;
    },
    setPermissionIdAndPermissionIndexToModalFormMutation(state, payload)
    {
        state.permission_id = payload.permission_id;
        state.permission_index = payload.permission_index;
    },
    addPermissionToPermissionList(state, payload)
    {
        state.permissionList.push(payload);
    },
    updatePermission(state, payload)
    {
        state.permissionList[payload.permission_index].title = payload.title;
        state.permissionList[payload.permission_index].slug = payload.slug;
    },
}
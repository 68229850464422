import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    user: {},

    userUpdateInterval: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

function checkAccess(roles, route_name, route_access)
{
    if (!roles.length) { return false; }
    if (route_access === 'all' || route_access === 'self') { return true; }

    let hasAccess = false

    for (let key in roles) {
        if (roles[key].permission) {
            let permissions = JSON.parse(roles[key].permission);

            if (permissions.length) {
                for (let jey in permissions) {
                    if (permissions[jey].name === route_name) {
                        if (permissions[jey].read === true) {
                            hasAccess = true;
                        }
                    }
                }
            }
        }
    }

    return hasAccess;
}

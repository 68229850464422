export default {
    getSettingList: (state) => {
        return state.settingList;
    },
    getSettingListCount: (state) => {
        return state.settingListCount;
    },
    getSettingMainFilter: (state) => {
        return state.settingFilter.main_filter;
    },
    getSettingFilterFields: (state) => {
        return state.settingFilter.fields;
    },
    getSettingModalOpen: (state) => {
        return state.settingModalOpen;
    },
    getSettingIdAndSettingIndex: (state) => {
        return {
            setting_id: state.setting_id,
            setting_index: state.setting_index
        };
    },
    getCountNotVerifiedClients: (state) => {
        return state.not_verified_clients;
    },
    getCountNotClosedTickets: (state) => {
        return state.not_closed_tickets;
    },
    getRoles: (state) => {
        return state.roles;
    },
    getPermissions: (state) => {
        return state.permissions;
    },
}

import router from "../../../router";
import axios from "axios";

export default {
    loadDocumentList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['documents/getDocumentFilterFields'];
            let main_filter = this.getters['documents/getDocumentMainFilter'];

            dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

            commit('setDocumentList', []);

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setDocumentFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.documentNumber && router.currentRoute.query.document_number) {
                filter_fields.documentNumber = router.currentRoute.query.document_number;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentNumber', field_value: router.currentRoute.query.document_number})
            }

            if (!filter_fields.documentCompanyTitle && router.currentRoute.query.document_company_title) {
                filter_fields.documentCompanyTitle = router.currentRoute.query.document_company_title;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentCompanyTitle', field_value: router.currentRoute.query.document_company_title})
            }

            if (!filter_fields.documentCompanyRole && router.currentRoute.query.document_company_role) {
                filter_fields.documentCompanyRole = router.currentRoute.query.document_company_role;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentCompanyRole', field_value: router.currentRoute.query.document_company_role})
            }

            if (!filter_fields.documentTitle && router.currentRoute.query.document_title) {
                filter_fields.documentTitle = router.currentRoute.query.document_title;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentTitle', field_value: router.currentRoute.query.document_title})
            }

            if (!filter_fields.documentUserName && router.currentRoute.query.document_user_name) {
                filter_fields.documentUserName = router.currentRoute.query.document_user_name;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentUserName', field_value: router.currentRoute.query.document_user_name})
            }

            if (!filter_fields.documentHash && router.currentRoute.query.document_hash) {
                filter_fields.documentHash = router.currentRoute.query.document_hash;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentHash', field_value: router.currentRoute.query.document_hash})
            }

            if (!filter_fields.documentCreatedAt && router.currentRoute.query.document_created_at) {
                filter_fields.documentCreatedAt = router.currentRoute.query.document_created_at;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentCreatedAt', field_value: router.currentRoute.query.document_created_at})
            }

            if (!filter_fields.documentStatus && router.currentRoute.query.document_status) {
                filter_fields.documentStatus = router.currentRoute.query.document_status;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentStatus', field_value: router.currentRoute.query.document_status})
            }

            if (!filter_fields.documentExecutorStampDate && router.currentRoute.query.document_executor_stamp_date) {
                filter_fields.documentExecutorStampDate = router.currentRoute.query.document_executor_stamp_date;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentExecutorStampDate', field_value: router.currentRoute.query.document_executor_stamp_date})
            }

            if (!filter_fields.documentCustomerStampDate && router.currentRoute.query.document_customer_stamp_date) {
                filter_fields.documentCustomerStampDate = router.currentRoute.query.document_customer_stamp_date;

                commit('setDocumentFilterFieldsMutation', {field_name: 'documentCustomerStampDate', field_value: router.currentRoute.query.document_customer_stamp_date})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/document/list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setDocumentList', response.data.data.result.documents);
                commit('setDocumentListCount', response.data.data.result.documents_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setDocumentFilterFields({ commit }, payload)
    {
        commit('setDocumentFilterFieldsMutation', payload);
    },
    deleteDocuments({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/document/delete-documents', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deleteDocumentsMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setDocumentModalOpen({commit}, payload)
    {
        commit('setDocumentModalOpenMutation', payload);
    },
    setDocumentIdAndDocumentIndexToModalForm({commit}, payload)
    {
        commit('setDocumentIdAndDocumentIndexToModalFormMutation', payload);
    },
    updateDocument({ commit }, payload)
    {
        commit('updateDocument', payload);
    },
    addDocumentToDocumentList({commit}, payload)
    {
        commit('addDocumentToDocumentList', payload);
    },
}

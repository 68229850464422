export default {
    getPermissionList: (state) => {
        return state.permissionList;
    },
    getPermissionListCount: (state) => {
        return state.permissionListCount;
    },
    getPermissionMainFilter: (state) => {
        return state.permissionFilter.main_filter;
    },
    getPermissionFilterFields: (state) => {
        return state.permissionFilter.fields;
    },
    getPermissionModalOpen: (state) => {
        return state.permissionModalOpen;
    },
    getPermissionIdAndPermissionIndex: (state) => {
        return {
            permission_id: state.permission_id,
            permission_index: state.permission_index
        };
    },
}

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    userList: [],
    userListCount: null,
    userFilter: {
        main_filter: null,
        fields: {
            userNumber: null,
            userName: null,
            userEmail: null,
            userType: 'admin',
        },
    },
    userModalOpen: false,
    user_id: null,
    user_index: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

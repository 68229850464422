export default {
    getTariffList: (state) => {
        return state.tariffList;
    },
    getTariffListCount: (state) => {
        return state.tariffListCount;
    },
    getTariffMainFilter: (state) => {
        return state.tariffFilter.main_filter;
    },
    getTariffFilterFields: (state) => {
        return state.tariffFilter.fields;
    },
    getTariffModalOpen: (state) => {
        return state.tariffModalOpen;
    },
    getTariffIdAndTariffIndex: (state) => {
        return {
            tariff_id: state.tariff_id,
            tariff_index: state.tariff_index
        };
    },
}

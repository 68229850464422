export default {
    setUserFilterFieldsMutation(state, payload)
    {
        state.userFilter.fields.userNumber = payload.user_number;
        state.userFilter.fields.userName = payload.user_name;
        state.userFilter.fields.userEmail = payload.user_email;
        state.userFilter.main_filter = payload.main_filter;
    },
    setUserList(state, payload)
    {
        state.userList = payload;
    },
    setUserListCount(state, payload) {
        state.userListCount = payload;
    },
    deleteUsersMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`user-id-${item}`).checked = false;

                index = state.userList.findIndex(user => user.id == item);

                if (index !== -1) {
                    state.userList.splice(index, 1);
                }
            });
        }
    },
    setUserModalOpenMutation(state, payload)
    {
        state.userModalOpen = payload;
    },
    setUserIdAndUserIndexToModalFormMutation(state, payload)
    {
        state.user_id = payload.user_id;
        state.user_index = payload.user_index;
    },
    addUserToUserList(state, payload)
    {
        state.userList.push(payload);
    },
    updateUser(state, payload)
    {
        state.userList[payload.user_index].name = payload.name;
        state.userList[payload.user_index].email = payload.email;
    },
}

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    tariffList: [],
    tariffListCount: null,
    tariffFilter: {
        main_filter: null,
        fields: {
            tariffNumber: null,
            tariffTitle: null,
            tariffCost: null,
            tariffStatus: null,
            tariffAmountDocuments: null,
        },
    },
    tariffModalOpen: false,
    tariff_id: null,
    tariff_index: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

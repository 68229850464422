export default {
    getPageList: (state) => {
        return state.pages;
    },
    getPageListCount: (state) => {
        return state.pageListCount;
    },
    getPageMainFilter: (state) => {
        return state.pageFilter.main_filter;
    },
    getPageFilterFields: (state) => {
        return state.pageFilter.fields;
    },
    getPageModalOpen: (state) => {
        return state.pageModalOpen;
    },
    getPageIdAndPageIndex: (state) => {
        return {
            page_id: state.page_id,
            page_index: state.page_index
        };
    },
}

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    permissionList: [],
    permissionListCount: null,
    permissionFilter: {
        main_filter: null,
        fields: {
            permissionNumber: null,
            permissionTitle: null,
            permissionSlug: null,
        },
    },
    permissionModalOpen: false,
    permission_id: null,
    permission_index: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

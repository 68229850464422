export default {
    setSettingFilterFieldsMutation(state, payload)
    {
        state.settingFilter.fields.settingNumber = payload.setting_number;
        state.settingFilter.fields.settingTitle = payload.setting_title;
        state.settingFilter.fields.settingSlug = payload.setting_slug;
        state.settingFilter.main_filter = payload.main_filter;
    },
    setSettingList(state, payload)
    {
        state.settingList = payload;
    },
    setSettingListCount(state, payload) {
        state.settingListCount = payload;
    },
    deleteSettingsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`setting-id-${item}`).checked = false;

                index = state.settingList.findIndex(setting => setting.id == item);

                if (index !== -1) {
                    state.settingList.splice(index, 1);
                }
            });
        }
    },
    setSettingModalOpenMutation(state, payload)
    {
        state.settingModalOpen = payload;
    },
    setSettingIdAndSettingIndexToModalFormMutation(state, payload)
    {
        state.setting_id = payload.setting_id;
        state.setting_index = payload.setting_index;
    },
    addSettingToSettingList(state, payload)
    {
        state.settingList.push(payload);
    },
    updateSetting(state, payload)
    {
        state.settingList[payload.setting_index].title = payload.title;
        state.settingList[payload.setting_index].slug = payload.slug;
    },
    setNotVerifiedClientCount(state, payload)
    {
        state.not_verified_clients = payload;
    },
    setNotClosedTickets(state, payload)
    {
        state.not_closed_tickets = payload;
    },
    setRoles(state, payload)
    {
        state.roles = payload;
    },
    setPermissions(state, payload)
    {
        state.permissions = payload;
    },
}

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import navigation from "./modules/navigation";
import documents from "./modules/documents";
import clients from "./modules/clients";
import tariffs from "./modules/tariffs";
import settings from "./modules/settings";
import accesses from "./modules/accesses";
import transactions from "./modules/transactions";
import tickets from "./modules/tickets";
import companies from "./modules/companies";
import currentUser from "./modules/current-user";
import pagePreloader from './modules/page-preloader';
import pages from './modules/pages';
import permissions from './modules/permissions';

export default new Vuex.Store({
    modules: {
        navigation,
        documents,
        clients,
        tariffs,
        settings,
        accesses,
        transactions,
        tickets,
        companies,
        currentUser,
        pagePreloader,
        pages,
        permissions,
    }
});

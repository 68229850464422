import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    settingList: [],
    settingListCount: null,
    settingFilter: {
        main_filter: null,
        fields: {
            settingNumber: null,
            settingTitle: null,
            settingSlug: null,
        },
    },
    settingModalOpen: false,
    setting_id: null,
    setting_index: null,

    not_verified_clients: null,
    not_closed_tickets: null,

    roles: [],
    permissions: [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
    import AppLayout from "@/layouts/AppLayout/AppLayout.vue";
    import EmptyLayout from "@/layouts/EmptyLayout/EmptyLayout.vue";
    import GuestLayout from "@/layouts/GuestLayout/GuestLayout.vue";

    export default {
        computed: {
            layout()
            {
                return (this.$route.meta.layout || 'EmptyLayout');
            }
        },
        components: {
            AppLayout,
            EmptyLayout,
            GuestLayout,
        },
        mounted() {
            if (localStorage.getItem("token")) {
                this.$store.dispatch('currentUser/startUserUpdateInterval');
            }
        },
    }
</script>

<style src="../public/css/common.css"></style>

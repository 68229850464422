export default {
    setTicketFilterFieldsMutation(state, payload)
    {
        state.ticketFilter.fields.ticketNumber = payload.ticket_number;
        state.ticketFilter.fields.ticketTitle = payload.ticket_title;
        state.ticketFilter.fields.ticketDescription = payload.ticket_description;
        state.ticketFilter.fields.ticketUserName = payload.ticket_user_name;
        state.ticketFilter.fields.ticketClosed = payload.ticket_closed;
        state.ticketFilter.fields.ticketCreatedAt = payload.ticket_created_at;
        state.ticketFilter.main_filter = payload.main_filter;
    },
    setTicketFilterFieldMutation(state, payload)
    {
        if (state.ticketFilter.fields[payload.field_name]) {
            state.ticketFilter.fields[payload.field_name] = payload.field_value;
        }
    },
    setTicketList(state, payload)
    {
        state.ticketList = payload;
    },
    setTicketListCount(state, payload) {
        state.ticketListCount = payload;
    },
    deleteTicketsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`ticket-id-${item}`).checked = false;

                index = state.ticketList.findIndex(ticket => ticket.id == item);

                if (index !== -1) {
                    state.ticketList.splice(index, 1);
                }
            });
        }
    },
    setTicketModalOpenMutation(state, payload)
    {
        state.ticketModalOpen = payload;
    },
    setTicketIdAndTicketIndexToModalFormMutation(state, payload)
    {
        state.ticket_id = payload.ticket_id;
        state.ticket_index = payload.ticket_index;
    },
    addTicketToTicketList(state, payload)
    {
        state.ticketList.unshift(payload);
    },
    updateTicket(state, payload)
    {
        state.ticketList[payload.ticket_index].title = payload.title;
        state.ticketList[payload.ticket_index].description = payload.description;
    },
}

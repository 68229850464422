export default {
    setClientFilterFieldsMutation(state, payload)
    {
        state.clientFilter.fields.userNumber = payload.user_number;
        state.clientFilter.fields.userName = payload.user_name;
        state.clientFilter.fields.userEmail = payload.user_email;
        state.clientFilter.fields.userType = payload.user_type;
        state.clientFilter.fields.userDeleted = payload.user_deleted;
        state.clientFilter.fields.userActivatedAt = payload.user_activated_at;
        state.clientFilter.fields.userCreatedAt = payload.user_created_at;
        state.clientFilter.fields.userPhone = payload.user_phone;
        state.clientFilter.main_filter = payload.main_filter;
    },
    setClientList(state, payload)
    {
        state.clientList = payload;
    },
    setClientListCount(state, payload) {
        state.clientListCount = payload;
    },
    deleteClientsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`client-id-${item}`).checked = false;

                index = state.clientList.findIndex(client => client.id == item);

                if (index !== -1) {
                    state.clientList.splice(index, 1);
                }
            });
        }
    },
    setClientModalOpenMutation(state, payload)
    {
        state.clientModalOpen = payload;
    },
    setClientIdAndClientIndexToModalFormMutation(state, payload)
    {
        state.client_id = payload.client_id;
        state.client_index = payload.client_index;
    },
    addClientToClientList(state, payload)
    {
        state.clientList.push(payload);
    },
    updateClient(state, payload)
    {
        state.clientList[payload.client_index].name = payload.name;
        state.clientList[payload.client_index].email = payload.email;
        state.clientList[payload.client_index].balance = payload.balance;
        state.clientList[payload.client_index].external_link_document_info = payload.external_link_document_info;
    },
    updateClientBalance(state, payload)
    {
        state.clientList[payload.client_index].balance = payload.balance;
    },
    setCurrentClientInfo(state, payload)
    {
        state.currentClient.id = payload.id;
        state.currentClient.name = payload.name;
        state.currentClient.phone = payload.phone;
        state.currentClient.transaction_article = payload.transaction_article ?? null;
        state.currentClient.transaction_comment = payload.transaction_comment ?? null;
        state.currentClient.transaction_action = payload.transaction_action ?? null;
        state.currentClient.wallet.id = payload.wallet.id;
        state.currentClient.wallet.balance = payload.wallet.balance;
    },
    setCurrentClientValue(state, payload)
    {console.log(payload);
        state.currentClient[payload.property_name] = payload.value;
    }
}

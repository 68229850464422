import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    ticketList: [],
    ticketListCount: null,
    ticketFilter: {
        main_filter: null,
        fields: {
            ticketNumber: null,
            ticketTitle: null,
            ticketDescription: null,
            ticketUserName: null,
            ticketClosed: null,
            ticketCreatedAt: null,
        },
    },
    ticketModalOpen: false,
    ticket_id: null,
    ticket_index: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
